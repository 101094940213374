import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vue3GoogleLogin from 'vue3-google-login'

import App from './App.vue';
import router from './Router'
import GlobalPopup from './components/GlobalPopup.vue';



const app = createApp(App);
app.use(router)
app.use(VueAxios, axios);
app.use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
  })
app.config.globalProperties.$showPopup = function(message, type) {
  this.$emit('show-popup', { message, type });
};
app.component('global-popup', GlobalPopup); 

app.mount('#app');