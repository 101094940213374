import { createRouter, createWebHistory } from 'vue-router'
import parkingForm from './components/ParkingForm.vue'
import HomeVue from './components/Home.vue'
import EmailVerification from './components/EmailVerification.vue'
import SuccessComponent from './components/Success.vue'
import { isTokenExpired } from './services/jwtService';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeVue,
    beforeEnter: (to, from, next) => {
      next({ name: 'VerifyEmail' });
    },
  },
  {
    path: '/verify',
    name: 'VerifyEmail',
    component: EmailVerification
  },
  {
    path: '/parking',
    name: 'ParkingForm',
    component: parkingForm,
    props: true,
    beforeEnter: (to, from, next) => {
      if (isTokenExpired()) {
        next({ name: 'VerifyEmail' }); 
      } else {
        next(); 
      }
    },
  
  },
  { path: '/success',
    name: 'Success',
    component: SuccessComponent 
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

