
<template>
    <div class="success-container">
        <div class="form-image">
            <img alt="Form Image" src="../assets/LinkedinCover-V2.jpg">
        </div>

        <div class="green-border"> </div>
        
        <div class="form-title">
            <h1>Parking form successfully submitted</h1>
            <p>An SMS will be sent with a parking permit.</p>
        </div>

    </div>
</template>
<script>
export default {
    name: 'SuccessComponent',
}
</script>
 
<style scoped>
    .success-container {
        display: flex;
        flex-direction: column;
        padding: 2em;
        max-width: 600px;
        margin: 0 auto;
    }

    .form-image {
        width: 100%;
        height: 150px;
        overflow: hidden;
        border-radius: 8px;
        margin-bottom: 1em;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0.3px 2px rgba(0, 0, 0, 0.24);
    }

    .form-image img {
        width: 100%;
        height: auto;
    }

    h1 {
        font-size: 1.25em;
        font-weight: 500;
        padding: 1em;
        border-bottom: 1px solid #dadce0;
        padding-top: 0;
    }   

    .form-title {
        margin-bottom: 1em;
        background-color: #ffffff;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        border: 1px solid #dadce0;
        border-top: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0.3px 2px rgba(0, 0, 0, 0.24); 
        padding-top: 0.75em;

    }

    .green-border{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 10px;
        left: -1px;
        position: relative;
        overflow: hidden;
        top: -1px;
        width: 100%;
        border: 1px solid #dadce0;
        border-bottom: none;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0.3px 2px rgba(0, 0, 0, 0.24); 
        background-color: #BCCE83;

    }
 

    .align-center{
        align-items: center;
    }


 
</style>