<template>
    <div v-if="show" class="popup">
      <p>{{ message }}</p>
      <button @click="close">Close</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      show: Boolean,
      message: String,
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style>
  .popup {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  }
  </style>
  