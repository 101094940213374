import jwtDecode from 'jsonwebtoken/decode';

export function decodeToken() {
    const parkingToken = localStorage.getItem('parking_token');
    const decoded = jwtDecode(parkingToken);
    return decoded;
}

export function isTokenExpired() {
    const decoded = decodeToken();
    if(!decoded){
        return true;
    }
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return decoded.exp < currentTimestamp;
}
