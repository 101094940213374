<template>
    <div class="form-container">
        <div class="form-image">
            <img alt="Form Image" src="../assets/LinkedinCover-V2.jpg">
        </div>

        <div class="green-border"></div>

         <div v-if="isLoading" class="spinner"></div>
         <div v-if="isLoading" class="overlay"></div>


        <div class="form-title">
            <h1>Email verification</h1>
            <span>Verify your email to fill out a parking form</span>

        </div>


    <!--  -->

    <div v-if="showPopup" class="overlay" @click="closePopup">
        <div class="popup" @click.stop>
            <p>{{ popupMessage }}</p>
            <button @click="closePopupButton">Close</button>
        </div>
     </div>

    <!--  -->
    
        <div v-if="!isEmailSent" :class="{ 'invalid-field': !formValidity.email }" class="form-group">
                <label for="email">Your email <span class="required">*</span></label>
                <input  class="form-input" id="email" type="email" v-model="formValues.email" placeholder="Enter your email">
        </div>

        <div class="or-separator">
            <span>or</span>
        </div>

        <div class="form-group align-center ">
            <GoogleLogin
                :callback="loginSuccess" 
                :error="loginFailure" 
            />
        </div>
        <!--  -->
<!-- 
            <div v-if="isEmailSent"  :class="{ 'invalid-field': !formValidity.verificationCode }" class="form-group">
                <label for="verificationCode">Verification code <span class="required">*</span></label>
                <input class="form-input" type="text" id="verificationCode"
                    v-model="formValues.verificationCode" placeholder="Enter verification code">
                
            </div> -->
        <div class="form-buttons">
            <button v-if="isEmailSent" @click="verifyCode">Submit Code</button>
            <button v-if="!isEmailSent" @click="verifyEmail">Verify</button>
        </div>
    </div>
</template>


<script>
import { GoogleLogin } from "vue3-google-login";
import { isTokenExpired } from '../services/jwtService';

const MESSAGES = {
  emailSent: 'Email sent successfully, please open the link sent to your Inbox.',
  emailSendFailed: 'Email failed to send.',
  emailLoginFailed: 'Email login failed.',
  tokenInvalid: 'Invalid sign in or token expired, please sign in again.',
  statusInvalid: 'Email is not recognised as one used to apply, please use same email address as on job application.'
};

const PARKING_SERVER_URL = process.env.VUE_APP_PARKING_SERVER_URL

export default {
    data() {
        return {
            isEmailSent: false,
            formValues: {
                // verificationCode: '',
                email: ''
            },
            formValidity: {
                // verificationCode: true,
                email: true
            },
            formErrors: {
                // verificationCode: '',
                email: ''
            },
            isLoading: false,
            showPopup: false,
            popupMessage: ''
        }
    },
    components: {
        GoogleLogin
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            // this.formValues.verificationCode = token

            localStorage.setItem('parking_token', token);
            localStorage.setItem('token_type', "jwt");

            this.verifyCode(token);  
        }
    },
    methods: {
        validateEmail(){
            let isValid = true;

            const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            if (!emailPattern.test(this.formValues.email)) {
                this.formErrors.email = 'Invalid email address.';
                isValid = false;
            } else {
                this.formErrors.email = '';
            }
            this.formValidity.email = this.formErrors.email === '';

            return isValid;
        },
        async loginSuccess(response) {
            this.isLoading = true;  

            localStorage.setItem('parking_token', response.credential);
            localStorage.setItem('token_type', "google");

            const credentials = {
                token_type: "google",
                token: response.credential,
            };
            if(!isTokenExpired()){
                this.$http.post(`${PARKING_SERVER_URL}/verify`, credentials)
                .then(res => {
                if (res.data.is_valid) {
                    this.$router.push({
                        name: 'ParkingForm',  
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.openPopup(error.message + ": " + error.response.data.error);
            });
            }
        },
        loginFailure(response) {
            console.log(response);
        },
        // validateCode(){
        //     let isValid = true;
        //     const codePattern = /\d{6}/;
        //     if (!codePattern.test(this.formValues.verificationCode)){
        //         this.formErrors.verificationCode = 'Invalid code.'
        //         isValid = false
        //     } else {
        //         this.formErrors.verificationCode = '';
        //     }
        //     this.formValidity.verificationCode = this.formErrors.verificationCode === '';
        //     return isValid;
        // },
        async verifyEmail() {
            if (!this.validateEmail()){
                return
            }
            this.isLoading = true;

            const email = {
                email: this.formValues.email
            }

            this.$http.post(`${PARKING_SERVER_URL}/token`, email)
            .then(() => {
                this.formValues.email = ''
                this.isLoading = false;
                this.openPopup(MESSAGES.emailSent)
            }).catch(error => {
                console.log(error.response);
                this.isLoading = false;
                this.openPopup(error.message + ": " + error.response.data.error)
            });
            // this.isEmailSent = true;
        },

        verifyCode(code) {
            // if (!this.validateCode()){
            //     return
            // }
            this.isLoading = true;  

            const credentials = {
                token_type: "jwt",
                token: code,
            };
            this.$http.post(`${PARKING_SERVER_URL}/verify`, credentials)
            .then(res => {
                if (res.data.is_valid) {
                    this.$router.push({
                        name: 'ParkingForm',  
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.openPopup(error.message + ": " + error.response.data.error);
            });
        },

        // Popup

    openPopup(message) {
      this.showPopup = true;
      this.popupMessage = message;
    },
    closePopup(event) {
      if (event && event.target.classList.contains('overlay')) {
        this.showPopup = false;
      } else if (!event) {
        this.showPopup = false;
      }
    },
    closePopupButton() {
        this.showPopup = false;
    }

    }
}
</script>

<style scoped>
    .form-container {
        display: flex;
        flex-direction: column;
        padding: 2em;
        max-width: 600px;
        margin: 0 auto;
    }

    .form-image {
        width: 100%;
        height: 150px;
        overflow: hidden;
        border-radius: 8px;
        margin-bottom: 1em;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0.3px 2px rgba(0, 0, 0, 0.24);
    }

    .form-image img {
        width: 100%;
        height: auto;
    }

    h1 {
        font-size: 1.25em;
        font-weight: 500;
        padding: 1em;
        border-bottom: 1px solid #dadce0;
        padding-top: 0;
    }   

    .form-title {
        margin-bottom: 1em;
        background-color: #ffffff;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        border: 1px solid #dadce0;
        border-top: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0.3px 2px rgba(0, 0, 0, 0.24); 
        padding-top: 0.75em;

    }


    .form-group {
        margin-bottom: 1em;
        background-color: #ffffff;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        border: 1px solid #dadce0;
        border-radius: 8px;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0.3px 2px rgba(0, 0, 0, 0.24); 
    }

    .green-border{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 10px;
        left: -1px;
        position: relative;
        overflow: hidden;
        top: -1px;
        width: 100%;
        border: 1px solid #dadce0;
        border-bottom: none;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0.3px 2px rgba(0, 0, 0, 0.24); 
        background-color: #BCCE83;

    }

    .align-center{
        align-items: center;
    }


    .g-btn-wrapper{
        width: fit-content !important;
    }

    .form-input {
        margin-top: 0.5em;
        padding: 10px;
        box-sizing: border-box;
        border-color: #ffffff;
        border-top: none;
        border-right: none;
        border-left: none;
    }


    .form-input:focus {
        border-color: #ECEDF0;
        outline: none;
    }

    .form-input:not(:placeholder-shown) {
        border-color: green;
    }

    .required {
        color: #d93025;
    }

    ::-webkit-input-placeholder { 
        opacity: 0.5 !important;
    }

    .form-buttons{
        display: flex;
        margin-left: auto;
    }

    button {
        width: 7em;
        color: #fff;
        background-color: #698426;
        border: none;
        border-radius: 4px;
        padding: 10px 0;
        font-size: 1em;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    button:hover {
        background-color: #738E30;
    }

    .error {
        color: #d93025;
        font-size: 0.8em;
    }

    .invalid-field {
        border-color: #d93025 !important; 
    }




    /* Separator */

    .or-separator {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1em 0;
        position: relative;
    }

    .or-separator span {
        background-color: #ffffff;
        padding: 0.5em 1em;
        z-index: 1;
    }

    .or-separator::before {
        content: "";
        height: 1px;
        width: 100%;
        background-color: #dadce0;
        position: absolute;
        z-index: 0;
    }


    /* Popup */

.popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(128, 128, 128, 0.5); 
    z-index: 9999; 
}

.spinner {
    border: 11px solid #f3f3f3;
    border-top: 11px solid  #BCCE83;
    border-radius: 50%;
    width: 74px;
    height: 74px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -37px; 
    margin-left: -37px; 
    z-index: 100000;
}


</style>