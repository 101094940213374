<template>
    <div class="form-container">
        <div class="form-image">
            <img alt="Form Image" src="../assets/LinkedinCover-V2.jpg">
        </div>

        <div class="green-border"> </div>
        <div v-if="isLoading" class="spinner"></div>
        <div v-if="isLoading" class="overlay"></div>

        
        <div class="form-title">
            <h1>Parking Permit Request</h1>
            <span class="required">* Indicates required question</span>
        </div>



        <div v-if="showPopup" class="overlay" @click="closePopup">
            <div class="popup" @click.stop>
                <p>{{ popupMessage }}</p>
                <button @click="closePopupButton">Close</button>
            </div>
        </div>

        <form @submit.prevent="submitForm">
            <div class="form-group" :class="{ 'invalid-field': !formValidity.first_name }">
                <label for="first_name">Your first name <span class="required">*</span></label>
                <input id="first_name" class="form-input" v-model="formValues.first_name" placeholder="Your first name"/>
                <div class="error" v-if="formErrors.first_name">{{ formErrors.first_name }}</div>
            </div>
            <div class="form-group" :class="{ 'invalid-field': !formValidity.last_name }">
                <label for="last_name">Your last name <span class="required">*</span></label>
                <input id="last_name" class="form-input" v-model="formValues.last_name" placeholder="Your last name"/>
                <div class="error" v-if="formErrors.last_name">{{ formErrors.last_name }}</div>
            </div>
            <div class="form-group"  :class="{ 'invalid-field': !formValidity.license_plate }">
                <label for="license_plate">License plate <span class="required">*</span></label>
                <input id="license_plate" class="form-input" v-model="formValues.license_plate" placeholder="License plate"/>
                <div class="error" v-if="formErrors.license_plate">{{ formErrors.license_plate }}</div>
            </div>
            <div class="form-group" :class="{ 'invalid-field': !formValidity.date }">
                <label for="date">Date <span class="required">*</span></label>
                <input id="date" type="date" class="form-input" v-model="formValues.date"/>
                <div class="error" v-if="formErrors.date">{{ formErrors.date }}</div>
            </div>
            <div class="form-group" :class="{ 'invalid-field': !formValidity.phone_number }">
                <label for="phone_number">Driver's phone number <span class="required">*</span></label>
                <input id="phone_number" class="form-input" v-model="formValues.phone_number" placeholder="Driver's phone number"/>
                <div class="error" v-if="formErrors.phone_number">{{ formErrors.phone_number }}</div>
            </div>
            <div class="form-buttons">
                <button type="submit" v-bind:disabled="hasSubmitted">Submit</button>
                <button type="reset">Clear form</button>
            </div>
        </form>
    </div>
</template>


<script>

const PARKING_SERVER_URL = process.env.VUE_APP_PARKING_SERVER_URL

export default {
    name: 'parkingForm',
    data() {
        return {
            formValues: {
                first_name: '',
                last_name: '',
                license_plate: '',
                date: '',
                phone_number: ''
            },
            formErrors: {
                first_name: '',
                last_name: '',
                license_plate: '',
                date: '',
                phone_number: ''
            },
            formValidity: {
                first_name: true,
                last_name: true,
                license_plate: true,
                date: true,
                phone_number: true
            },
            isLoading: false,
            showPopup: false,
            hasSubmitted: false,
            popupMessage: ''
        };
    },
    methods: {
        validateForm() {
            let isValid = true;


            ['first_name', 'last_name', 'license_plate'].forEach(field => {
                if (this.formValues[field] === '') {
                    this.formErrors[field] = 'This field is required.';
                    isValid = false;
                } else {
                    this.formErrors[field] = '';
                }
            });
            ['first_name', 'last_name', 'license_plate'].forEach(field => {
                this.formValidity[field] = this.formErrors[field] === '';
            });


            if (this.formValues.date === '') {
                this.formErrors.date = 'This field is required.';
                isValid = false;
            } else {
                this.formErrors.date = '';
            }
            this.formValidity.date = this.formErrors.date === '';

            const phonePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; 
            if (!phonePattern.test(this.formValues.phone_number)) {
                this.formErrors.phone_number = 'Invalid phone number.';
                isValid = false;
            } else {
                this.formErrors.phone_number = '';
            }
            this.formValidity.phone_number = this.formErrors.phone_number === '';

            return isValid;
        },

        async submitForm() {
            this.isLoading = true;
            if (!this.validateForm()) {
                return;
            }
            this.hasSubmitted = true;
            window.scrollTo(0,0);
            const response = {
                formId: 'parkingForm',
                formValues: this.formValues
            };

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('parking_token')
            }

            await this.$http.post(`${PARKING_SERVER_URL}/parking`, response, {
                headers: headers
            })
            .then(res => {
                if(res.data.success){
                    this.$router.push({
                        name: 'Success',  
                    });
                    localStorage.removeItem('parking_token');
                    localStorage.removeItem('token_type');
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.openPopup(error.message + ": " + error.response.data.message);
                this.isLoading = false;
            });
            for (let value in this.formValues) delete this.formValues[value];

        },
    // Popup

    openPopup(message) {
      this.showPopup = true;
      this.popupMessage = message;
    },
    closePopup(event) {
      if (event && event.target.classList.contains('overlay')) {
        this.showPopup = false;
      } else if (!event) {
        this.showPopup = false;
      }
    },
    closePopupButton() {
        this.showPopup = false;
    }


    }
}
</script>

<style scoped>
    .form-container {
        display: flex;
        flex-direction: column;
        padding: 2em;
        
        max-width: 600px;
        margin: 0 auto;
    }

    .form-image {
        width: 100%;
        height: 150px;
        overflow: hidden;
        border-radius: 8px;
        margin-bottom: 1em;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0.3px 2px rgba(0, 0, 0, 0.24);
    }

    .form-image img {
        width: 100%;
        height: auto;
    }

    h1 {
        font-size: 1.25em;
        font-weight: 500;
        padding: 1em;
        border-bottom: 1px solid #dadce0;
        padding-top: 0;

    }

    ::-webkit-input-placeholder { 
        opacity: 0.5 !important;
    }

    .form-title {
        margin-bottom: 1em;
        background-color: #ffffff;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        border: 1px solid #dadce0;
        border-top: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0.3px 2px rgba(0, 0, 0, 0.24); 
        padding-top: 0.75em;

    }


    .form-group {
        margin-bottom: 1em;
        background-color: #ffffff;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        border: 1px solid #dadce0;
        border-radius: 8px;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0.3px 2px rgba(0, 0, 0, 0.24); 
    }

    .green-border{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 10px;
        left: -1px;
        position: relative;
        overflow: hidden;
        top: -1px;
        width: 100%;
        border: 1px solid #dadce0;
        border-bottom: none;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 0.3px 2px rgba(0, 0, 0, 0.24); 
        background-color: #BCCE83;

    }
    .active-loader {
        position: relative;
        overflow: hidden;
        background-color: #BCCE83
    }
    
    .active-loader::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 10px;  
        width: 100%;
        background: linear-gradient(90deg, #BCCE83 30%, #f4f6f0 50%, #BCCE83 70%);
        animation: loader 1.5s infinite;
    }

    @keyframes loader {
        0% {
            left: -100%;
        }
        100% {
            left: 100%;
        }
    } 


    .form-input {
        margin-top: 0.5em;
        padding: 10px;
        box-sizing: border-box;
        border-color: #ffffff;
        border-top: none;
        border-right: none;
        border-left: none;
    }

    .form-input:focus {
        border-color: #ECEDF0;
        outline: none;
    }
    .form-input:not(:placeholder-shown) {
        border-color: green;
    }


    .required {
        color: #d93025;
    }

    .form-buttons{
        display: flex;
        justify-content: space-between;
    }

    button {
        width: 7em;
        border: none;
        border-radius: 4px;
        padding: 10px 0;
        font-size: 1em;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    button[type="submit"]{
        color: #fff;
        background-color: #698426;

    }

    button[type="reset"]{
        color: #698426;
        background-color: #F3F5E7;

    }

    button:hover[type="submit"] {
        background-color: #738E30;
    }

    button:hover[type="reset"] {
        background-color: #EDEDED;
    }

    .error {
        color: #d93025;
        font-size: 0.8em;
    }   

    .invalid-field {
        border-color: #d93025 !important; 
    }

    /* Popup */

    .popup {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
    }


    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }



    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(128, 128, 128, 0.5); 
        z-index: 9999; 
    }

    .spinner {
        border: 11px solid #f3f3f3;
        border-top: 11px solid  #BCCE83;
        border-radius: 50%;
        width: 74px;
        height: 74px;
        animation: spin 2s linear infinite;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -37px; 
        margin-left: -37px; 
        z-index: 100000;
    }



</style>
