<template>
  <!-- <div id="app"> -->
    <router-view />
    <GlobalPopup :show="showPopup" :message="popupMessage" @close="closePopup" />
  <!-- </div> -->
</template>

<script>
import GlobalPopup from './components/GlobalPopup.vue';


export default {
  name: 'App',
  components: {
    // parkingForm,
    // HomeVue
    GlobalPopup,

  },
  data() {
    return {
      showPopup: false,
      popupMessage: '',
    };
  },
  methods: {
    openPopup(message) {
      this.showPopup = true;
      this.popupMessage = message;
    },
    closePopup() {
      this.showPopup = false;
    },
  },
  provide() {
    return {
      openPopup: this.openPopup,
      closePopup: this.closePopup,
    };
  },

}
</script>

<style>
body {
  background-color: #F3F5E7;

}
#app::before {
  content: ""; 
  position: absolute;
  top: 0;
  left: 0;
  height: 5px; 
  width: 100%;
  background-color: #BCCE83;
}

#app {
  font-family: 'Poppins', Roboto, RobotoDraft, Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
  margin-bottom: 60px;
  background-color: #F3F5E7;
}
.popup {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  }
</style>
