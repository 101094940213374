<!-- <template>
    <h1>Welcome!</h1>
    <p>Please <router-link :to="{
        name: 'ParkingForm',  params: { formTitle: 'Parking Permit Request' }
        }">click here</router-link> to fill out the parking form.</p>
  </template>
  
  <script>
  export default {
    name: 'HomeVue',
  }
  </script> -->

  <template>
    <h1>Welcome!</h1>
    <p>Please <router-link :to="{name: 'VerifyEmail'}">click here</router-link> to verify your email address.</p>
</template>

<script>
export default {
    name: 'HomeVue',
}
</script>